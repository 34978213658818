import React, { useEffect } from "react";
import "./PriceOptionsCards.css";

import Aos from "aos";
import "aos/dist/aos.css";

const PriceOptionsCards = () => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  });
  const priceData = [
    // {
    //   titlePlan: "Basic",
    //   titleDescription:
    //     "Essential support and guidance for e-commerce beginners, covering up to 10 items.",
    //   serviceName: "$499",
    //   tickIcon: require("../assets/images/Tick.png"),
    //   serviceDescription: "Basic account setup and support",
    //   serviceDescription2: "Product listing assistance for up to 10 items",
    //   serviceDescription3: "Access to beginner tutorials and resources",
    // },
    {
      titlePlan: "Basic",
      titleDescription:
        "Optimize your store with expert assistance and marketing strategies for up to 50 products.",
      serviceName: "$999",
      tickIcon: require("../assets/images/Tick.png"),
      serviceDescription: "Full account setup and optimization",
      serviceDescription2: "Product listing management for up to 50 items",
      serviceDescription3: "Basic marketing and advertising support",
      serviceDescription4: "Monthly performance reports",
      serviceDescription5: "Customer service management",
    },
    {
      titlePlan: "Standard",

      // titleDescription:
      //   "Elevate your online presence with advanced optimization and dedicated support for up to 100 products.",
      titleDescription:
        "Elevate your online presence with advanced optimization and support for 100 products",
      serviceName: "$1999",
      tickIcon: require("../assets/images/Tick.png"),
      serviceDescription:
        "Comprehensive account setup and advanced optimization",
      serviceDescription2: "Product listing management for up to 100 items",
      serviceDescription3: "Advanced marketing and advertising strategies",
      serviceDescription4: "Weekly performance analytics",
      serviceDescription5: "Dedicated account manager",
      serviceDescription6: "Customer service management",
      serviceDescription7: "Inventory management support",
      serviceDescription8: "Access to premium resources and tutorials",
    },
    {
      titlePlan: "Premium",

      // titleDescription:
      //   "Ultimate e-commerce solution with custom-tailored services & dedicated support for your entire business.",
      titleDescription:
        "Ultimate e-commerce solution with custom services & dedicated support for your business",
      serviceName: "$4999",
      tickIcon: require("../assets/images/Tick.png"),
      serviceDescription: "Tailored account setup and full optimization",
      serviceDescription2: "Unlimited product listing management",
      serviceDescription3: "Custom marketing and advertising campaigns",
      serviceDescription4: "Real-time performance analytics",
      serviceDescription5: "Dedicated team of account managers",
      serviceDescription6: "Comprehensive customer service solutions",
      serviceDescription7: "Advanced inventory and supply chain management",
      serviceDescription8: "Customized training and support",
      serviceDescription9: "Access to exclusive tools and resources",
    },
  ];

  const navigateToContactSection = () => {
    const element = document.getElementById("contactSection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="price-options">
      <div className="container py-5">
        <div className="text-center">
          <h1
            className="font-face-Bold"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            Choose a variety of{" "}
            <span className="d-md-block">price options</span>
          </h1>
          <p
            className="font-face-Poppins-Medium"
            data-aos="zoom-in"
            data-aos-duration="1100"
          >
            Explore our diverse range of pricing plans tailored to meet your
            needs. Whether you're seeking premium quality or budget-friendly
            choices, we offer options to suit every preference.
            <span className="d-md-block">
              Enjoy exceptional value and flexibility without compromising on
              quality. Discover the perfect plan for you today
            </span>
          </p>
        </div>

        <div className="price-main row pt-5 justify-content-md-center">
          {priceData?.map((x, index) => {
            return (
              <div
                key={index}
                className="price-box col-xl-4 col-lg-6 col-md-6 mb-5 mb-xl-0"
              >
                <div className="inner p-3">
                  <span className="font-face-Bold">{x.titlePlan}</span>
                  <p className="price-text text-lg-start text-md-start font-face-Poppins-Medium">
                    {x.titleDescription}
                  </p>

                  <div>
                    <h1 className="price-heading font-face-Bold">
                      {x.serviceName}
                    </h1>
                  </div>
                  <div className="custom-scroll">
                    <div className="d-flex align-items-start mt-4">
                      <img src={x.tickIcon} alt="tickImage" />
                      <p className="font-face-Poppins-Medium ms-3">
                        {x.serviceDescription}
                      </p>
                    </div>

                    <div className="d-flex align-items-start">
                      <img src={x.tickIcon} alt="tickImage" />
                      <p className="font-face-Poppins-Medium ms-3">
                        {x.serviceDescription2}
                      </p>
                    </div>

                    <div className="d-flex align-items-start">
                      <img src={x.tickIcon} alt="tickImage" />
                      <p className="font-face-Poppins-Medium ms-3">
                        {x.serviceDescription3}
                      </p>
                    </div>

                    {x.serviceDescription4 ? (
                      <div className="d-flex align-items-start">
                        <img src={x.tickIcon} alt="tickImage" />
                        <p className="font-face-Poppins-Medium ms-3">
                          {x.serviceDescription4}
                        </p>
                      </div>
                    ) : null}

                    {x.serviceDescription5 ? (
                      <div className="d-flex align-items-start">
                        <img src={x.tickIcon} alt="tickImage" />
                        <p className="font-face-Poppins-Medium ms-3">
                          {x.serviceDescription5}
                        </p>
                      </div>
                    ) : null}
                    {x.serviceDescription6 ? (
                      <div className="d-flex align-items-start">
                        <img src={x.tickIcon} alt="tickImage" />
                        <p className="font-face-Poppins-Medium ms-3">
                          {x.serviceDescription6}
                        </p>
                      </div>
                    ) : null}
                    {x.serviceDescription7 ? (
                      <div className="d-flex align-items-start">
                        <img src={x.tickIcon} alt="tickImage" />
                        <p className="font-face-Poppins-Medium ms-3">
                          {x.serviceDescription7}
                        </p>
                      </div>
                    ) : null}
                    {x.serviceDescription8 ? (
                      <div className="d-flex align-items-start">
                        <img src={x.tickIcon} alt="tickImage" />
                        <p className="font-face-Poppins-Medium ms-3">
                          {x.serviceDescription8}
                        </p>
                      </div>
                    ) : null}
                    {x.serviceDescription9 ? (
                      <div className="d-flex align-items-start">
                        <img src={x.tickIcon} alt="tickImage" />
                        <p className="font-face-Poppins-Medium ms-3">
                          {x.serviceDescription9}
                        </p>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-0 mt-lg-5 mt-md-5" />
                  <div className="text-center">
                    <button
                      onClick={navigateToContactSection}
                      className="price-btn"
                    >
                      Choose Plan
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PriceOptionsCards;
